import React, { Component } from "react";
import { ToastContainer } from "react-toastify";
import {
  NewsletterFormSection,
  NewsletterDescription
} from "../../components/_newsletter";
export default class Home extends Component {
  render() {
    return (
     <React.Fragment>
       <ToastContainer position="bottom-right" />
       <NewsletterFormSection />
       <NewsletterDescription />
     </React.Fragment>
    );
  }
}
