import React, { Component } from "react";
import { Grid } from "semantic-ui-react";
import Logo from "../../../assets/images/logoprimary.png";
export default class MainBanner extends Component {
  render() {
    return (
      <Grid centered className="quote-banner-grid">
        <Grid.Row>
          <Grid.Column
            verticalAlign="middle"
            mobile={16}
            tablet={16}
            computer={8}
          >
            <img src={Logo} alt="" />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column mobile={12} tablet={12} computer={8}>
            <blockquote>
              <p className="quote-banner-quote">
                <span>" Twoje pożywienie powinno być lekarstwem,</span>
                <span>a twoje lekarstwo powinno być pożywieniem. "</span>
              </p>
              <cite className="quote-banner-author">~ Hipokrates</cite>
            </blockquote>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }
}
