import React, { Component } from "react";
import { Grid, Image } from "semantic-ui-react";
import CustomOfferItem from "../CustomOfferItem";
import BrookeLark from "../../../assets/images/brooke-lark.jpg";
import SharonPittaway from "../../../assets/images/sharon-pittaway.jpg";
import MajaPetric from "../../../assets/images/maja-petric.jpg";

export default class OfferSection extends Component {
  render() {
    return (
      <Grid centered className="offer-section-grid" columns={2}>
        <Grid.Row className="offer-section-row">
          <Grid.Column
            className="noPadding"
            mobile={16}
            tablet={16}
            computer={8}
          >
            <CustomOfferItem
              className="column-offer"
              header="Pełna konsultacja"
              text="Na początku musimy się poznać, zrobimy wywiad żywieniowy, wywiad zdrowotny, opowiesz mi o sobie, o swoim stylu życia, nawykach żywieniowych oraz przedstawisz mi swoje objawy. Zadam Ci pytania, które pomogą w utworzeniu indywidualnej terapii. Dostarczysz odpowiednie badania, które należałoby wykonać, przedstawisz mi swoje preferencje żywieniowe, alergie pokarmowe czy ewentualne przyjmowane leki, a ja łącząc te wszystkie informacje otrzymane od Ciebie, przedstawię Ci mój plan na poprawę Twojego zdrowia."
            />
          </Grid.Column>
          <Grid.Column
            className="noPadding"
            mobile={16}
            tablet={16}
            computer={8}
          >
            <Image className="column-offer" src={BrookeLark} />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row className="offer-section-row noPadding">
          <Grid.Column
            className="noPadding"
            mobile={16}
            tablet={16}
            computer={8}
          >
            <Image className="column-offer" src={SharonPittaway} />
          </Grid.Column>
          <Grid.Column
            className="noPadding"
            mobile={16}
            tablet={16}
            computer={8}
          >
            <CustomOfferItem
              className="column-offer"
              header="Omówienie Twojej terapii"
              text="Podsumowując wszelkie informacje na Twój temat, przedstawię Ci moją terapię. Otrzymasz zalecenia żywieniowe razem z jadłospisem, zalecaną aktywnością fizyczną, suplementacją oraz indywidualną mieszankę ziołową wycelowaną w Twój problem zdrowotny."
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row className="offer-section-row noPadding">
          <Grid.Column
            className="noPadding"
            mobile={16}
            tablet={16}
            computer={8}
          >
            <CustomOfferItem
              className="column-offer"
              header="Modyfikacja zaleceń"
              text="W informacji zwrotnej od Ciebie w miarę trwania terapii będziemy ją modyfikować o poszczególne aspekty. Ocenimy efektywność mieszanki ziołowej oraz dodamy ewentualne modyfikacje. Wprowadzimy zmiany w diecie, odnosząc się do uzyskanych efektów terapeutycznych. W razie potrzeby zmodyfikujemy suplementację czy też zmienimy zalecenia żywieniowe zgodnie z Twoimi preferencjami."
            />
          </Grid.Column>
          <Grid.Column
            className="noPadding"
            mobile={16}
            tablet={16}
            computer={8}
          >
            <Image className="column-offer" src={MajaPetric} />
          </Grid.Column>
        </Grid.Row>

        {/* <Grid.Row className="offer-section-header-row">
            <Grid.Column width={12}>
              <Header className="offer-main-header" as="h2">
                Oferta
              </Header>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row className="offer-section-row">
            <Grid.Column className="offer-section-col">
              <CustomOfferItem
                header="Pełna konsultacja"
                text="Na początku musimy się poznać, zrobimy wywiad żywieniowy, wywiad
              zdrowotny, opowiesz mi o sobie, o swoim stylu życia, nawykach
              żywieniowych oraz przedstawisz mi swoje objawy. Zadam Ci
              odpowienie pytania które pomogą w utworzeniu indywidualnej
              terapii. Dostarczysz odpowiednie badania któe należałoby wykonać,
              ustalimy nasze cele na diagnozę oraz przedstawię Ci mój plan na
              poprawę Twojego zdrowia. Przedstawisz mi swoje preferencje
              żywieniowe, alergie pokarmowe czy ewentualne przyjmowane leki."
              />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row className="offer-section-row">
            <Grid.Column className="offer-section-col">
              <CustomOfferItem
                header="Omówienie Twojej terapii"
                text="Podsumowując wszelkie informacje na Twój temat przedstawię Ci moją
              terapię. Otrzymasz zalecenia żywieniowe razem z jadłospisem,
              zalecaną aktywnością fizyczną, suplementacją oraz indywidualną
              mieszankę ziołową wycelowaną w Twój problem zdrowotny."
              />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row className="offer-section-row">
            <Grid.Column className="offer-section-col">
              <CustomOfferItem
                header="Modyfikacja zaleceń"
                text="W informacji zwrotnej od Ciebie w miarę trwania terapii będziemy
              ją modyfikować o poszczególne aspekty. Ocenimy efektywność
              mieszanki ziołowej oraz dodamy ewentualne modyfikacje. Wprowadzimy
              zmiany w diecie odnosząc się do uzyskanych efektów
              terapeutycznych. W razie potrzeby zmodyfikujemy suplementajcję czy
              też zmienimy zalecenia żywieniowe zgodnie z Twoimi preferencjami."
              />
            </Grid.Column>
          </Grid.Row>
        <Grid.Row>
          <Grid.Column verticalAlign="middle">
            <ForwardContactSection />
          </Grid.Column>
        </Grid.Row> */}
      </Grid>
    );
  }
}
