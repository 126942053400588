import React from "react";
import { Header, Container } from "semantic-ui-react";

const CustomStructureItem = (props) => {
  return (
    <React.Fragment>
      <Container className="structure-item-container">
        <Header as="h2" className="subsection-header">
          {props.structureHeader}
        </Header>
        <p>{props.structureText}</p>
      </Container>
    </React.Fragment>
  );
};

export default CustomStructureItem;
