import React, { Component } from "react";
import { Grid, Header } from "semantic-ui-react";
import ModalImage from "react-modal-image";
import Edukator from "../../../assets/images/edukator.jpg";
import Kaczmarek from "../../../assets/images/kaczmarek.jpg";
import Lucy from "../../../assets/images/lucy.jpg";
import Jeremy from "../../../assets/images/jeremy.jpg";
import Dyplom from "../../../assets/images/dyplom.jpg";
class CertificateCards extends Component {
  render() {
    return (
      <React.Fragment>
        <Grid centered>
          <Grid.Row>
            <Grid.Column mobile={16} tablet={16} computer={12}>
              <Header className="certificate-header" as="h2">
                Moje osiagnięcia
              </Header>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row verticalAlign="middle">
            <Grid.Column mobile={16} tablet={16} computer={5}>
              <div className="certificate-modal-section">
                <ModalImage
                className="modal-image"
                  hideDownload={true}
                  hideZoom={true}
                  small={Edukator}
                  large={Edukator}
                />
              </div>
            </Grid.Column>
            <Grid.Column mobile={16} tablet={16} computer={5}>
              <div className="certificate-modal-section">
                <ModalImage
                  hideDownload={true}
                  hideZoom={true}
                  small={Kaczmarek}
                  large={Kaczmarek}
                />
              </div>
            </Grid.Column>
            <Grid.Column mobile={16} tablet={16} computer={5}>
              <div className="certificate-modal-section">
                <ModalImage
                  hideDownload={true}
                  hideZoom={true}
                  small={Lucy}
                  large={Lucy}
                />
              </div>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row verticalAlign="middle">
            <Grid.Column mobile={16} tablet={16} computer={6}>
              <div className="certificate-modal-section">
                <ModalImage
                  hideDownload={true}
                  hideZoom={true}
                  small={Jeremy}
                  large={Jeremy}
                />
              </div>
            </Grid.Column>
            <Grid.Column mobile={16} tablet={16} computer={6}>
              <div className="certificate-modal-section">
                <ModalImage
                  hideDownload={true}
                  hideZoom={true}
                  small={Dyplom}
                  large={Dyplom}
                />
              </div>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </React.Fragment>
    );
  }
}

export default CertificateCards;
