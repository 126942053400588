import React, { Component } from "react";
import { CoopQuoteBanner, OfferSection, ForwardContactSection } from "../../components/_cooperation";
export default class Cooperation extends Component {
  render() {
    return (
      <React.Fragment>
        <CoopQuoteBanner />
        <OfferSection />
        <ForwardContactSection />
      </React.Fragment>
    );
  }
}
