import React from "react";
import { Container, Segment, Header } from "semantic-ui-react";

const NewsletterSquare = (props) => {
  return (
    <Container>
      <Segment className="newsletter-square-segment" style={{marginBottom: '5vh'}}>
        <Header as="h2">{props.header}</Header>
        <p>{props.text}</p>
      </Segment>
    </Container>
  );
};

export default NewsletterSquare;
