import React from "react";
import { Grid, Image, Header } from "semantic-ui-react";
import NewsletterPros from "../NewsletterPros";
import Logo from "../../../assets/images/LogoThird.png";
const NewsletterDescription = () => {
  return (
    <Grid className="newsletter-description-grid" centered columns="2">
      <Grid.Row>
        <Grid.Column mobile={16} tablet={16} computer={12}>
          <Header as="h2">Dlaczego warto dołączyć?</Header>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column mobile={16} tablet={16} computer={7}>
          <Image style={{margin: 'auto'}} src={Logo} size="medium" />
        </Grid.Column>
        <Grid.Column
          verticalAlign="middle"
          mobile={16}
          tablet={16}
          computer={7}
        >
          <NewsletterPros />
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};

export default NewsletterDescription;
