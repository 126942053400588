import {
  EMAIL_ERROR,
} from "../../actions";

const emailsReducer = (state = { isError: false }, action) => {
  switch (action.type) {
    // case SEND_EMAIL:
    //   return { ...state, sendResult: action.payload };
    // case IS_SUCCESSFULLY_SENT:
    //   return { ...state, isSuccess: action.payload };
    case EMAIL_ERROR:
      return { ...state, isError: action.isError };
    default:
      return state;
  }
};

export default emailsReducer;
