import "./App.css";
import React from "react";
import Navbar from "./layout/Navbar";
import Home from "./pages/Home";
import About from "./pages/About";
import Cooperation from "./pages/Cooperation";
import Newsletter from "./pages/Newsletter";
import Contact from "./pages/Contact";
import Footer from "./layout/Footer";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fab } from "@fortawesome/free-brands-svg-icons";
import "./assets/scss/styles.scss";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import CookieConsent from "react-cookie-consent";
import CookiesPolicy from "./assets/pdfs/Polityka_cookies.pdf";
import ScrollToTop from "./layout/ScrollToTop";
library.add(fab);

class App extends React.Component {
  render() {
    return (
      <div className="App">
        <Router>
          <Navbar />
          <ScrollToTop />
          <Switch>
            <Route exact path="/">
              <Home />
            </Route>
            <Route path="/o-mnie">
              <About />
            </Route>
            <Route path="/wspolpraca">
              <Cooperation />
            </Route>
            <Route path="/newsletter">
              <Newsletter />
            </Route>
            <Route path="/kontakt">
              <Contact />
            </Route>
          </Switch>
          <Footer />
          <CookieConsent debug={true} location="bottom" buttonText="Akceptuję">
            Ta strona korzysta z ciasteczek aby świadczyć usługi na najwyższym
            poziomie. Dalsze korzystanie ze strony oznacza, że zgadzasz się na
            ich użycie. Więcej informacji znajdziesz w{" "}
            <a href={CookiesPolicy} target="_blank" rel="noopener noreferrer">
              polityce cookies
            </a>
            .
          </CookieConsent>
        </Router>
      </div>
    );
  }
}
export default App;
