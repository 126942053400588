import React, { Component } from "react";
import { Header, Grid, Segment } from "semantic-ui-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope, faPhone } from "@fortawesome/free-solid-svg-icons";
export default class ContactDetails extends Component {
  render() {
    return (
      <Grid className="contact-grid" columns={2}>
        <Grid.Row className="contact-grid-row">
          <Grid.Column mobile={16} tablet={16}>
            <Header as="h2">Skontaktuj się</Header>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row className="contact-grid-row" verticalAlign="middle">
          <Grid.Column className="contact-grid-col" mobile={12} tablet={12} computer={8}>
            <Segment className="contact-icon-segment icon-text-inline">
              <FontAwesomeIcon icon={faPhone} size="2x" />
              <p>883 143 908</p>
            </Segment>
          </Grid.Column>
          <Grid.Column className="contact-grid-col" mobile={12} tablet={12} computer={8}>
            <Segment className="contact-icon-segment icon-text-inline">
              <FontAwesomeIcon icon={faEnvelope} size="2x" />
              <p>terapie.boruta@gmail.com</p>
            </Segment>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row className="contact-grid-row">
          <Grid.Column className="contact-grid-col" mobile={12} tablet={12} computer={8}>
            <a
              href="https://www.facebook.com/Naturalne-Terapie-Maciej-Boruta-107880264718426"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Segment className="contact-icon-segment icon-text-inline">
                <FontAwesomeIcon icon={["fab", "facebook-f"]} size="2x" />
                <p>Facebook</p>
              </Segment>
            </a>
          </Grid.Column>
          <Grid.Column className="contact-grid-col" mobile={12} tablet={12} computer={8}>
            <a
              href="https://www.instagram.com/terapieboruta/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Segment className="contact-icon-segment icon-text-inline">
                <FontAwesomeIcon icon={["fab", "instagram"]} size="2x" />
                <p>Instagram</p>
              </Segment>
            </a>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row className="contact-grid-row contact-youtube-link" columns={1}>
          <Grid.Column className="contact-grid-col" mobile={12} tablet={12} computer={16}>
            <a
              href="https://www.youtube.com/channel/UCtfnUbdacb6f3a8ViUzfPXw"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Segment className="contact-icon-segment icon-text-inline">
                <FontAwesomeIcon icon={["fab", "youtube"]} size="2x" />
                <p>Youtube</p>
              </Segment>
            </a>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }
}
