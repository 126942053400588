import React from "react";
import { Segment, Header } from "semantic-ui-react";
import { Link } from "react-router-dom"
const ForwardContactSection = () => {
  return (
    <React.Fragment>
      <Segment className="offer-forward-section">
        <Header className="offer-forward-header" as="h1">
          Jesteś zainteresowany? Skontaktuj się ze mną.
        </Header>
        <div className="buttonContainer">
          <Link to="/kontakt">
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            Przejdź do kontaktu
          </Link>
        </div>
      </Segment>
    </React.Fragment>
  );
};

export default ForwardContactSection;
