import React from "react";
import { Header, Segment } from "semantic-ui-react";
import { Link } from "react-router-dom";
const CustomForwardingItem = (props) => {
  return (
    <React.Fragment>
      <Segment className="custom-forwarding-item">
        <Header className="custom-forwarding-item-header" as="h2">
          {props.header}
        </Header>
        <p className="custom-forwarding-item-paragraph">{props.text}</p>
        <div className="buttonContainer">
          <Link to={props.to}>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            {props.buttonText}
          </Link>
        </div>
      </Segment>
    </React.Fragment>
  );
};

export default CustomForwardingItem;
