import React from "react";
import { Grid } from "semantic-ui-react";
import IntroText from "../IntroText";
import EmbedMedia from "../EmbedMedia";

const IntroSection = () => {
  return (
    <Grid columns={2} className="intro-section">
      <Grid.Row className="intro-section-row">
        <Grid.Column mobile={16} tablet={16} computer={8} className="intro-section-text">
          <IntroText />
        </Grid.Column>
        <Grid.Column mobile={16} tablet={16} computer={8} className="intro-section-media">
          <EmbedMedia />
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};

export default IntroSection;
