import React from 'react'
import { Embed } from 'semantic-ui-react'
import YoutubePhoto from '../../../assets/images/YoutubePhoto.png'
const EmbedMedia = () => (
  <Embed
    id='ls9pIcfF0nE'
    placeholder={YoutubePhoto}
    source='youtube'
  />
)

export default EmbedMedia;