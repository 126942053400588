import React, { Component } from "react";
import {
  HomeQuoteBanner,
  IntroSection,
  IdeologySection,
  ForwardingSection
} from "../../components/_home";
export default class Home extends Component {
  render() {
    return (
      <React.Fragment>
        <HomeQuoteBanner />
        <IntroSection />
        <IdeologySection />
        <ForwardingSection />
      </React.Fragment>
    );
  }
}
