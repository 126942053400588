import React, { Component } from "react";
import { ToastContainer } from "react-toastify";
import { ContactSection } from "../../components/_contact";
import "react-toastify/dist/ReactToastify.css";
export default class Contact extends Component {
  render() {
    return (
      <React.Fragment>
        <ToastContainer position="bottom-right" />
        <ContactSection />
      </React.Fragment>
    );
  }
}
