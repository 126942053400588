import React, { Component } from "react";
import { Grid, Header, Image } from "semantic-ui-react";
import AboutPhoto from "../../../assets/images/AboutPhoto.png";
export default class AboutDescription extends Component {
  render() {
    return (
      <Grid
        className="about-description-grid"
        centered
        columns={2}
        verticalAlign="middle"
      >
        <Grid.Row>
          <Grid.Column mobile={12} tablet={12} computer={6}>
            <Image style={{ margin: "auto" }} src={AboutPhoto} size="small" />
          </Grid.Column>
          <Grid.Column
            mobile={12}
            tablet={12}
            computer={6}
            className="about-description-text"
          >
            <Header as="h1">Cześć,</Header>
            <p>
              Mam na imię Maciek, ukończyłem studia na uniwersytecie medycznym w
              Białymstoku, gdzie zdobyłem dyplom dietetyka klinicznego. Prawie
              dwa lata pracowałem w zawodzie w szpitalu. Jednak samo układanie
              diet to było dla mnie za mało, dodatkowo interesowałem się
              medycyną chińską oraz jej elementami.
            </p>
            <p>
              Jestem przekonany, że odpowiednia dietoterapia, indywidualna
              suplementacja połączona wraz z ukierunkowanym ziołolecznictwem,
              może znacznie poprawić stan zdrowia w różnych jednostkach
              chorobowych. Obecnie kończę kilkuletnią akademię naturopatii,
              gdzie poznałem jak diagnozować oraz dobrać odpowiednią terapię
              według zasad medycyny chińskiej. Jest to ogromna wiedza, dlatego
              cały czas uczestniczę w kursach, szkoleniach, by móc zgłębiać
              swoją wiedzę i skuteczniej pomagać pacjentom.
            </p>
            <p>
              Dodatkowo jestem członkiem Polskiego Towarzystwa Ozonoterapii,
              ponieważ w swojej ofercie posiadam również zabiegi rękawem
              ozonowym.
            </p>
            <p>
              Serdecznie zapraszam do kontaktu, postaram się pomóc oraz odpowiem
              na wszelkie pytania.
            </p>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }
}
