import React, { Component } from "react";
import CustomStructureItem from "../CustomStructureItem";
import Slider from "react-slick";
import { Grid, Header } from "semantic-ui-react";
const sections = [
  {
    name: "Dietoterapia",
    text: "Jak wiadomo, prawidłowa dieta to podstawa naszego zdrowia. Każdemu z nas konkretna dieta jest potrzebna w innym celu. Często jest to po prostu chęć zrzucenia kilogramów. Jednak w moich terapiach skupiam się na większych problemach jak choroby nowotworowe, przewlekłe, wada wzroku, problemy hormonalne itp. Dieta wraz z odpowiednią suplementacją jest jednym z fundamentów moich terapii. Łącząc wiedzę akademicką z zasadami dietetyki według medycyny chińskiej, można bardzo wesprzeć odbudowę zdrowia pacjenta.",
  },
  {
    name: "Ziołolecznictwo",
    text: "Będąc dietetykiem, ale także naturopatą jedną z metod, jaką stosuje, jest fitoterapia, która jest nieodzownym elementem skutecznego leczenia chorób. Właściwe stosowanie ziół może w znacznym stopniu poprawić samopoczucie i dopomóc w leczeniu farmakologicznym. Zaletą fitoterapii jest to, że jest to naturalna metoda, która nie posiada takich skutków ubocznych jak chemiczne leki. Zioła zawierają mnóstwo witamin, sole mineralne, garbniki, olejki eteryczne i inne składniki niezbędne do prawidłowego funkcjonowania organizmu człowieka. Poprzez dokładną diagnostykę danej osoby, dobranie przeze mnie indywidualnej mieszanki ziołowej przynosi znaczącą poprawę pacjentom.",
  },
  {
    name: "Suplementacja",
    text: "Odpowiednio dobrana suplementacja idealnie wspiera i jest często znaczącym czynnikiem poprawy zdrowia pacjenta. Wiele schorzeń wynika z niedoboru konkretnych witamin, które mają wpływ na poszczególne mechanizmy naszego organizmu. Aby utrzymać prawidłową homeostazę, nasz organizm potrzebuje dostarczać odpowiednie ilości składników mineralnych, których często brakuje, prowadząc nieodpowiednie żywienie. Z doświadczenia widzę, że u pacjentów coraz częściej pojawiają się niedobory witamin i minerałów więc kluczowym jest ich uzupełnienie w celu poprawy stanu naszego zdrowia. W ramach wizyty otrzymasz indywidualną rozpiskę z suplementacją, która jest jedną z elementów mojej terapii.",
  },
  {
    name: "Ozonoterapia",
    text: "Jestem członkiem Polskiego Towarzystwa Ozonoterapii i Oferuję zabiegi ozonem medycznym wytwarzanym przez profesjonalne urządzenie. Rękaw bądź but ozonowy wykorzystuje działanie mieszaniny tlenowo-ozonowej w różnych stężeniach w formie gazowej. W zabiegu bierze udział specjalistyczna osłona foliowa nakładana na kończyny dolne. Metodę z użyciem rękawa ozonowego sugeruje się szczególnie pacjentom zmagającym się z owrzodzeniem stopy spowodowanym przez cukrzycę lub miażdżycą, jak i w przypadku występowania owrzodzenia żylakowego podudzi. But czy też rękaw ozonowy, w znacznym stopniu przyspiesza leczenie ran. Oprócz tego zabieg stosuje się również profilaktycznie u osób chorujących na cukrzycę. Metoda ta uchroniła od amputacji kończyny dolnej wielu pacjentów zmagających się ze stopą cukrzycową. Ozon wytwarzany przez generator jest wolny od szkodliwych tlenków azotu.",
  },
];
export default class CustomSectionSlicer extends Component {
  render() {
    const settings = {
      dots: true,
      fade: true,
      infinte: true,
      speed: 500,
      slidesToShow: 1,
      arrows: true,
      slidesToScroll: 1,
      className: "slides",
    };
    return (
      <Grid className="slider-grid" centered>
        <Grid.Row>
          <Grid.Column mobile={16} tablet={16} computer={12}>
            <Header className="slider-header" as="h2">
              Fundament mojej pracy
            </Header>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column className="slider-section-text" width={16}>
            <Slider
              style={{ backgroundColor: "rgb(23, 92, 14)" }}
              {...settings}
            >
              {sections.map((section) => {
                return (
                  <div key={section.name}>
                    <CustomStructureItem
                      structureHeader={section.name}
                      structureText={section.text}
                    />
                  </div>
                );
              })}
            </Slider>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }
}
