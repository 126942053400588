import React from "react";
import { useDispatch } from "react-redux";
import {
  Segment,
  Header,
  Container,
  Button,
  Message,
  Form,
} from "semantic-ui-react";
import { Formik } from "formik";
import * as yup from "yup";
import isEmpty from "lodash.isempty";
import { sendNewsletterEmails } from "../../../store/actions";
import PrivacyPolicy from "../../../assets/pdfs/Polityka_prywatności.pdf"
export const NewsletterForm = () => {
  const [state] = React.useState({
    firstName: "",
    email: "",
    acceptTerms: false,
  });
  const dispatch = useDispatch();

  const handleSubmit = (values, setSubmitting, resetForm) => {
    dispatch(sendNewsletterEmails(values, setSubmitting, resetForm));
  };

  const validationSchema = yup.object().shape({
    firstName: yup
      .string()
      .required("Imię nie może być puste")
      .min(3, "Imię jest za krótkie (minimum 3 znaki)"),
    email: yup
      .string()
      .email("Pole musi mieć format adresu email")
      .required("Adres email nie może być pusty"),
    acceptTerms: yup.bool().oneOf([true], "Akceptuję politykę prywatności."),
  });

  return (
    <Container>
      <Segment className="newsletter-form">
        <Header className="newsletter-form-header" as="h1">
          Newsletter Naturalnych Terapii
        </Header>
        <p className="newsletter-form-paragraph">
          Zapisz się na darmowy Newsletter Naturalnych terapii, aby być na
          bieżąco z najnowszymi nowinkami ze świata dietetyki oraz otrzymać
          darmowe porady odnośnie poprawy zdrowia oraz kondycji.
        </p>
        <Formik
          initialValues={{
            ...state,
          }}
          validationSchema={validationSchema}
          onSubmit={(values, { setSubmitting, resetForm }) => {
            handleSubmit(values, setSubmitting, resetForm);
          }}>
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            setFieldValue,
            isSubmitting,
          }) => (
            <Form loading={isSubmitting}>
              <Form.Input
                required
                label="Imię"
                fluid
                type="text"
                name="firstName"
                value={values.firstName}
                onChange={handleChange}
                onBlur={handleBlur}
                error={errors.firstName && touched.firstName !== undefined}
              />
              {errors.firstName && touched.firstName ? (
                <Message negative content={errors.firstName} />
              ) : null}
              <Form.Input
                required
                label="Email"
                fluid
                type="text"
                name="email"
                value={values.email}
                onChange={handleChange}
                onBlur={handleBlur}
                error={errors.email && touched.email !== undefined}
              />
              {errors.email && touched.email ? (
                <Message negative content={errors.email} />
              ) : null}
              <Form.Checkbox
                required
                label={
                  <label>
                    Akceptuję <a href={PrivacyPolicy} target="_blank" rel="noopener noreferrer">politykę prywatności.</a>
                  </label>
                }
                name="acceptTerms"
                checked={values.acceptTerms}
                onChange={() =>
                  setFieldValue("acceptTerms", !values.acceptTerms)
                }
              />
              <Button
                className="newsletter-form-button"
                type="submit"
                onClick={handleSubmit}
                loading={isSubmitting}
                disabled={isSubmitting || !isEmpty(errors)}
              >
                Zapisz mnie
              </Button>
            </Form>
          )}
        </Formik>
      </Segment>
    </Container>
  );
};

export default NewsletterForm;
