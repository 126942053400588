import React from "react";
import { Grid, Header, Image } from "semantic-ui-react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEnvelope,
  faPhone,
  faCopyright,
} from "@fortawesome/free-solid-svg-icons";
import PrivatePolicy from "../../assets/pdfs/Polityka_prywatności.pdf";
import CookiesPolicy from "../../assets/pdfs/Polityka_cookies.pdf";
import Logo from "../../assets/images/LogoThird.png";

const Footer = () => {
  return (
    <Grid className="footer-grid">
      <Grid.Row className="footer-main-row">
        <Grid.Column mobile={16} tablet={16} computer={4}>
          <Image className="footer-logo" src={Logo} />
        </Grid.Column>
        <Grid.Column
          className="footer-items"
          mobile={16}
          tablet={16}
          computer={3}
        >
          <Header as="h3">Nawigacja</Header>
          <Link to="/">Strona główna</Link>
          <Link to="/o-mnie">O mnie</Link>
          <Link to="/wspolpraca">Współpraca</Link>
          <Link to="/newsletter">Newsletter</Link>
          <Link to="/kontakt">Kontakt</Link>
        </Grid.Column>
        <Grid.Column
          className="footer-items"
          mobile={16}
          tablet={16}
          computer={3}
        >
          <Header as="h3">Zasady</Header>
          <a href={PrivatePolicy} target="_blank" rel="noopener noreferrer">
            Polityka prywatności
          </a>
          <a href={CookiesPolicy} target="_blank" rel="noopener noreferrer">
            Polityka cookies
          </a>
        </Grid.Column>
        <Grid.Column
          className="footer-items"
          mobile={16}
          tablet={16}
          computer={3}
        >
          <Header as="h3">Kontakt</Header>
          <div className="icon-text-inline">
            <FontAwesomeIcon
              color="white"
              className="social-media-item"
              icon={faPhone}
              size="1x"
            />
            <p>883 143 908</p>
          </div>
          <div className="icon-text-inline" style={{ marginBottom: "2vh" }}>
            <FontAwesomeIcon
              color="white"
              className="social-media-item"
              icon={faEnvelope}
              size="1x"
            />
            <p>terapie.boruta@gmail.com</p>
          </div>

          <div className="footer-social-section">
            <a
              className="footer-social-item"
              href="https://www.facebook.com/Naturalne-Terapie-Maciej-Boruta-107880264718426"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FontAwesomeIcon
                className="footer-social-icon"
                icon={["fab", "facebook-f"]}
                size="2x"
              />
            </a>
            <a
              className="footer-social-item"
              href="https://www.instagram.com/terapieboruta/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FontAwesomeIcon
                className="footer-social-icon"
                icon={["fab", "instagram"]}
                size="2x"
              />
            </a>
            <a
              className="footer-social-item"
              href="https://www.youtube.com/channel/UCtfnUbdacb6f3a8ViUzfPXw"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FontAwesomeIcon
                className="footer-social-icon"
                icon={["fab", "youtube"]}
                size="2x"
              />
            </a>
          </div>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row centered>
        <Grid.Column
          className="icon-text-inline footer-items-centered"
          width={16}
        >
          <FontAwesomeIcon color="white" icon={faCopyright} size="1x" />
          <div className="footer-copyright-paragraph">
            2021 Naturalne Terapie Maciej Boruta. Wszelkie prawa zastrzeżone.
            <p>
              Wykonananie:{" "}
              <a
                href="https://www.linkedin.com/in/w-wesolowski/"
                target="_blank"
                rel="noopener noreferrer"
              >
                w-wesolowski
              </a>
            </p>
          </div>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};

export default Footer;
