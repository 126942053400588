import React, { Component } from "react";
import { Grid, Image } from "semantic-ui-react";
import CooperationPhoto from "../../../assets/images/CooperationPhoto.jpg";
export default class QuoteBanner extends Component {
  render() {
    return (
      <Grid className="offer-quote-grid" centered>
        <Grid.Row>
          <Grid.Column mobile={14} tablet={12} computer={10}>
            <blockquote>
              <p className="offer-quote-text">
                <span>"Pan stworzył z ziemi lekarstwa,</span>
                <span>a człowiek mądry nie będzie nimi gardził."</span>
              </p>
              <cite className="offer-quote-author">(Syr 38, 4)</cite>
            </blockquote>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column mobile={16} tablet={16} computer={6}>
            <Image className="offer-quote-photo" src={CooperationPhoto} />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }
}
