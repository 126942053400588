import React from "react";
import { Grid, Image } from "semantic-ui-react";
import NewsletterForm from "../NewsletterForm";
import NewsletterImage from "../../../assets/images/NewsletterImage.jpg";
const NewsletterFormSection = () => {
  return (
    <Grid className="newsletter-grid" centered columns={2}>
      <Grid.Row verticalAlign="middle">
        <Grid.Column mobile={16} tablet={16} computer={7}>
          <NewsletterForm />
        </Grid.Column>
        <Grid.Column mobile={16} tablet={16} computer={7}>
          <Image src={NewsletterImage} />
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};

export default NewsletterFormSection;
