import React, { Component } from "react";
import { Header, Grid } from "semantic-ui-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faUsers,
  faMortarPestle,
  faStethoscope,
  faHeartbeat,
  faStreetView,
  faRunning,
} from "@fortawesome/free-solid-svg-icons";
export default class Ideology extends Component {
  render() {
    return (
      <Grid className="ideology-grid" centered columns={2}>
        <Grid.Row>
          <Grid.Column mobile={16} tablet={16} computer={12}>
            <Header className="ideology-grid-header" as="h2">
              Zasady mojej działalności
            </Header>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column className="ideology-grid-fcol align-col" mobile={12} tablet={12} computer={6}>
            <FontAwesomeIcon
              className="ideology-icon fa-fw"
              icon={faUsers}
              size="4x"
              style={{ width: "1em" }}
            />
            <div className="ideology-text">
              Do każdego pacjenta podchodzę indywidualnie, tylko wtedy można
              uzyskać znaczącą poprawę zdrowia
            </div>
          </Grid.Column>
          <Grid.Column className="align-col" mobile={12} tablet={12} computer={6}>
            <FontAwesomeIcon
              className="ideology-icon"
              icon={faStethoscope}
              size="4x"
            />
            <div className="ideology-text">
              Każdą osobę staram się dokładnie zdiagnozować co pozwola na
              ustalenie odpowiedniej terapii
            </div>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column className="ideology-grid-fcol align-col" mobile={12} tablet={12} computer={6}>
            <FontAwesomeIcon
              className="ideology-icon"
              icon={faMortarPestle}
              size="4x"
            />
            <div className="ideology-text">
              W swojej praktyce używam naturalnych metod dzięki którym pacjenci
              odzyskują zdrowie
            </div>
          </Grid.Column>
          <Grid.Column className="align-col" mobile={12} tablet={12} computer={6}>
            <FontAwesomeIcon
              className="ideology-icon"
              icon={faStreetView}
              size="4x"
            />
            <div className="ideology-text">
              Holistyczne podejście do pacjenta jest dla mnie główną zasadą
              którą się kieruję
            </div>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column className="ideology-grid-fcol align-col" mobile={12} tablet={12} computer={6}>
            <FontAwesomeIcon
              style={{ fontSize: "4em" }}
              className="ideology-icon"
              icon={faHeartbeat}
              size="4x"
            />
            <div className="ideology-text">
              Moje terapie są wycelowane w taki sposób aby nie tylko usunąć
              objawy ale głównie przyczyny utraty zdrowia
            </div>
          </Grid.Column>
          <Grid.Column className="align-col" mobile={12} tablet={12} computer={6}>
            <FontAwesomeIcon
              className="ideology-icon fa-fw"
              icon={faRunning}
              size="4x"
            />
            <div className="ideology-text">
              Dieta, odpowiedni styl życia oraz aktywność fizyczna są podstawą
              naszego zdrowia
            </div>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }
}
