import React from "react";
import { Header } from "semantic-ui-react";
const CustomOfferItem = (props) => {
  return (
    <React.Fragment>
      <div className="offer-item-section">
        <div className="offer-item-div">
          <Header className="offer-text-header" as="h1">
            {props.header}
          </Header>
          <p className="offer-text">{props.text}</p>
        </div>
      </div>
    </React.Fragment>
  );
};

export default CustomOfferItem;
