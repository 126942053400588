import React, { Component } from "react";
import { AboutDescription, CustomSectionSlider, CertificateCards } from "../../components/_about";
export default class About extends Component {
  render() {
    return (
      <React.Fragment>
        <AboutDescription />
        <CustomSectionSlider />
        <CertificateCards />
      </React.Fragment>
    );
  }
}
