import React, { useState } from "react";
import { Menu, Image } from "semantic-ui-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Logo from "../../assets/images/logosecondary.png";
import { Link, NavLink } from "react-router-dom";
const Navbar = () => {
  const [state, setState] = useState({ active: false, burgerClass: "burger" });

  const addClassOnClick = () => {
    const currentState = state.active;
    const navLinks = document.querySelectorAll(".main-navigation a");
    navLinks.forEach((link, index) => {
      if (link.style.animation) {
        link.style.animation = "";
      } else {
        link.style.animation = `navLinkFade 0.5s ease forwards ${
          index / 7 + 0.5
        }s`;
      }
    });
    var css = state.burgerClass === "burger" ? "burger toggle" : "burger";
    setState({ active: !currentState, burgerClass: css });
  };

  return (
    <div className="navigation-bar">
      <Menu className="navigation-bar-nav" fixed="top" pointing secondary>
        <Image
          as={Link}
          to="/"
          className="logo-img"
          src={Logo}
          size="tiny"
        />
        <Menu.Menu
          position="right"
          className={
            state.active
              ? "main-navigation navigation-active"
              : "main-navigation"
          }
        >
          <Menu.Item
            name="home"
            as={NavLink}
            exact
            to="/"
            activeClassName="active"
            onClick={state.active ? addClassOnClick : undefined}
          >
            Strona główna
          </Menu.Item>
          <Menu.Item
            name="about"
            as={NavLink}
            exact
            to="/o-mnie"
            activeClassName="active"
            onClick={state.active ? addClassOnClick : undefined}
          >
            O mnie
          </Menu.Item>
          <Menu.Item
            name="cooperation"
            as={NavLink}
            exact
            to="/wspolpraca"
            activeClassName="active"
            onClick={state.active ? addClassOnClick : undefined}
          >
            Współpraca
          </Menu.Item>
          <Menu.Item
            name="newsletter"
            as={NavLink}
            exact
            to="/newsletter"
            activeClassName="active"
            onClick={state.active ? addClassOnClick : undefined}
          >
            Newsletter
          </Menu.Item>
          <Menu.Item
            name="contact"
            as={NavLink}
            exact
            to="/kontakt"
            activeClassName="active"
            onClick={state.active ? addClassOnClick : undefined}
          >
            Kontakt
          </Menu.Item>
        </Menu.Menu>

        <Menu.Menu className="social-media-section" position="right">
          <a
            href="https://www.facebook.com/Naturalne-Terapie-Maciej-Boruta-107880264718426"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FontAwesomeIcon
              className="social-media-item"
              icon={["fab", "facebook-f"]}
              size="3x"
            />
          </a>
          <a
            href="https://www.instagram.com/terapieboruta/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FontAwesomeIcon
              className="social-media-item"
              icon={["fab", "instagram"]}
              size="3x"
            />
          </a>
          <a
            href="https://www.youtube.com/channel/UCtfnUbdacb6f3a8ViUzfPXw"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FontAwesomeIcon icon={["fab", "youtube"]} size="3x" />
          </a>
        </Menu.Menu>
        <div className={state.burgerClass} onClick={addClassOnClick}>
          <div className="line1"></div>
          <div className="line2"></div>
          <div className="line3"></div>
        </div>
      </Menu>
    </div>
  );
};

export default Navbar;
