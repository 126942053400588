import React from "react";
import { Grid, Header } from "semantic-ui-react";
import CustomForwardingItem from "../CustomForwardingItem";
const ForwardingSection = () => {
  return (
    <Grid centered>
      <Grid.Column mobile={16} tablet={16} computer={12}>
        <Header className="forwarding-section-header" as="h2">
          Co znajdziesz na stronie?
        </Header>
      </Grid.Column>
      <Grid.Column
        className="forwarding-section-col"
        mobile={16}
        tablet={16}
        computer={5}
        verticalAlign="middle"
      >
        <CustomForwardingItem
          header="Współpraca"
          to="/wspolpraca"
          text="Jeśli jesteś zainteresowany poprawą swojego zdrowia i zaciekawiła Cię moja oferta, zapoznaj się z zakładką współpraca, gdzie przeczytasz, jak wygląda moja praca."
          buttonText="Kliknij tutaj"
        />
      </Grid.Column>
      <Grid.Column
        className="forwarding-section-col"
        mobile={16}
        tablet={16}
        computer={5}
        verticalAlign="middle"
      >
        <CustomForwardingItem
          header="Newsletter"
          to="/newsletter"
          text="Jeżeli chcesz regularnie otrzymywać ciekawe informacje oraz być na bieżąco z nowinkami w świecie medycyny naturalnej, zapraszam Cię do zapisania się na mój newsletter."
          buttonText="Kliknij tutaj"
        />
      </Grid.Column>
      <Grid.Column
        className="forwarding-section-col"
        mobile={16}
        tablet={16}
        computer={5}
        verticalAlign="middle"
      >
        <CustomForwardingItem
          header="Kontakt"
          to="/kontakt"
          text="Jeśli masz jakieś pytanie lub jesteś zainteresowany współpracą ze mną, wyślij wiadomość poprzez formularz kontaktowy lub innymi drogami komunikacji dostępnymi w zakładce kontakt."
          buttonText="Kliknij tutaj"
        />
      </Grid.Column>
    </Grid>
  );
};

export default ForwardingSection;
