import React from "react";
import { useDispatch } from "react-redux";
import {
  Segment,
  Header,
  Container,
  Button,
  Message,
  Form,
} from "semantic-ui-react";
import { Formik } from "formik";
import * as yup from "yup";
import isEmpty from "lodash.isempty";
import { sendContactEmail } from "../../../store/actions";
import PrivacyPolicy from "../../../assets/pdfs/Polityka_prywatności.pdf";
export const ContactForm = () => {
  const [state] = React.useState({
    firstName: "",
    lastName: "",
    email: "",
    textContent: "",
    acceptTerms: false,
  });
  const dispatch = useDispatch();

  const handleSubmit = (values, setSubmitting, resetForm) => {
    dispatch(sendContactEmail(values, setSubmitting, resetForm));
  };

  const validationSchema = yup.object().shape({
    firstName: yup
      .string()
      .required("Imię nie może być puste")
      .min(3, "Imię jest za krótkie (minimum 3 znaki)"),
    lastName: yup
      .string()
      .required("Nazwisko nie może być puste")
      .min(3, "Nazwisko jest za krótkie (minimum 3 znaki)"),
    email: yup
      .string()
      .email("Pole musi mieć format adresu email")
      .required("Adres email nie może być pusty"),
    textContent: yup
      .string()
      .required("Treść nie może być pusta")
      .min(20, "Treść jest za krótka (minimum 20 znaków)"),
    acceptTerms: yup.bool().oneOf([true], "Akceptuję politykę prywatności."),
  });

  return (
    <Container className="contact-form">
      <Segment className="contact-form-segment">
        <Header as="h2">Formularz kontaktowy</Header>
        <Formik
          initialValues={{
            ...state,
          }}
          validationSchema={validationSchema}
          onSubmit={(values, { setSubmitting, resetForm }) => {
            handleSubmit(values, setSubmitting, resetForm);
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            setFieldValue,
            isSubmitting,
          }) => (
            <Form loading={isSubmitting}>
              <Form.Input
                required
                label="Imię"
                fluid
                type="text"
                name="firstName"
                value={values.firstName}
                onChange={handleChange}
                onBlur={handleBlur}
                error={errors.firstName && touched.firstName !== undefined}
              />
              {errors.firstName && touched.firstName ? (
                <Message negative content={errors.firstName} />
              ) : null}
              <Form.Input
                required
                label="Nazwisko"
                fluid
                type="text"
                name="lastName"
                value={values.lastName}
                onChange={handleChange}
                onBlur={handleBlur}
                error={errors.lastName && touched.lastName !== undefined}
              />
              {errors.lastName && touched.lastName ? (
                <Message negative content={errors.lastName} />
              ) : null}
              <Form.Input
                required
                label="Email"
                fluid
                type="text"
                name="email"
                value={values.email}
                onChange={handleChange}
                onBlur={handleBlur}
                error={errors.email && touched.email !== undefined}
              />
              {errors.email && touched.email ? (
                <Message negative content={errors.email} />
              ) : null}
              <Form.TextArea
                className="contact-form-textcontent"
                label="Treść"
                type="text"
                name="textContent"
                value={values.textContent}
                onChange={handleChange}
                onBlur={handleBlur}
                rows={3}
                error={errors.textContent && touched.textContent !== undefined}
              />
              {errors.textContent && touched.textContent ? (
                <Message negative content={errors.textContent} />
              ) : null}
              <Form.Checkbox
                required
                label={
                  <label>
                    Akceptuję{" "}
                    <a
                      href={PrivacyPolicy}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Politykę Prywatności.
                    </a>
                  </label>
                }
                name="acceptTerms"
                checked={values.acceptTerms}
                onChange={() =>
                  setFieldValue("acceptTerms", !values.acceptTerms)
                }
              />
              <Button
                primary
                type="submit"
                onClick={handleSubmit}
                loading={isSubmitting}
                disabled={isSubmitting || !isEmpty(errors)}
              >
                Wyślij
              </Button>
            </Form>
          )}
        </Formik>
      </Segment>
    </Container>
  );
};

export default ContactForm;
