import React from "react";
import { Header } from "semantic-ui-react";
import { Link } from "react-router-dom";
const IntroText = () => {
  return (
    <div className="introtext-section">
      <Header className="introtext-header" as="h2">
        Czym są Naturalne Terapie?
      </Header>
      <p className="introtext-param">
        Jeśli tu trafiłeś, najprawdopodobniej poszukujesz pomocy w poprawie
        swojego zdrowia. Poprzez odpowiednio dobraną dietę, suplementację oraz
        mieszankę ziołową można bardzo wiele zmienić. Kieruję się indywidualnym
        podejściem do pacjenta, działamy bezpośrednio na przyczynę problemu, a
        nie tylko na jego objawy. Niekiedy dieta wymaga różnych poświęceń, ale
        jestem przekonany o wartości, jaką to ma dla poprawy samopoczucia,
        zdrowia, kondycji fizycznej, jak i psychicznej.
      </p>
      <p className="introtext-param">
        Jestem młodym, ambitnym dietetykiem-naturopatą, który przede wszystkim
        chce Ci pomóc. Aby skuteczniej pomagać moim pacjentom, cały czas
        uczestniczę w kursach i szkoleniach. Zapraszam Cię do zapoznania się z
        moją ofertą.
      </p>
      <div className="buttonContainer">
        <Link to="/o-mnie">
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          Poznaj więcej
        </Link>
      </div>
    </div>
  );
};

export default IntroText;
