import React from 'react'
import { Grid } from "semantic-ui-react"
import ContactDetails from "../ContactDetails"
import ContactForm from "../ContactForm"
const ContactSection = () => {
    return (
        <Grid centered>
            <Grid.Row columns={2}>
                <Grid.Column mobile={16} tablet={16} computer={7}>
                    <ContactDetails />
                </Grid.Column>
                <Grid.Column mobile={16} tablet={16} computer={7}>
                    <ContactForm />
                </Grid.Column>
            </Grid.Row>
        </Grid>
    )
}

export default ContactSection;