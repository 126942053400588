import React from "react";
import { Container } from "semantic-ui-react";
import NewsletterSquare from "../NewsletterSquare";
const NewsletterPros = () => {
  return (
    <Container>
      <NewsletterSquare
        header="Darmowa dieta"
        text="Otrzymasz ode mnie darmowy dwudniowy jadłospis."
      />
      <NewsletterSquare
        header="Zniżki"
        text="Będziesz na bieżąco z promocjami na moje usługi."
      />
      <NewsletterSquare
        header="Ciekawostki"
        text="Co tydzień otrzymasz ode mnie ciekawe informacje na temat zdrowego odżywiania, ziół, suplementacji i wiele innych."
      />
    </Container>
  );
};

export default NewsletterPros;
