import apiClient from "../../api/apiClients";

import { EMAIL_ERROR, IS_SUCCESSFULLY_SENT } from "./types";

import { toast } from "react-toastify";

const switchSuccess = (status) => (dispatch) => {
  dispatch({ type: IS_SUCCESSFULLY_SENT, payload: status });
};
const handleAndDispatchError = (dispatch) => {
  toast.error("Wystąpił problem z wysłaniem wiadomości");
  dispatch({ type: EMAIL_ERROR, isError: true });
};

const sendNewsletterEmails =
  (values, setSubmitting, resetForm) => async (dispatch) => {
    // dispatch(setLoadOn());
    try {
      let ownerResponse = await apiClient.post(
        "/emails/sendNewsletterMessage",
        values
      );
      let clientResponse = await apiClient.post(
        "/emails/sendNewsletterConfirm",
        values
      );
      if (ownerResponse.status !== 200 && clientResponse.status !== 200) {
        toast.error("Problem z zapisem na newsletter.");
        return;
      }
      toast.success("Udało Ci się zapisać na Newsletter!");
      resetForm({});
      dispatch(switchSuccess(true));
      setSubmitting(false);
    } catch {
      handleAndDispatchError(dispatch);
    }
    //dispatch(setLoadOff());
  };
const sendContactEmail =
  (values, setSubmitting, resetForm) => async (dispatch) => {
    // dispatch(setLoadOn());
    try {
      let emailResponse = await apiClient.post(
        "/emails/sendContactEmail",
        values
      );
      if (emailResponse.status !== 200) {
        toast.error("Wiadomość nie została wysłana");
        return;
      }
      toast.success("Wiadomość wysłana pomyślnie");
      resetForm({});
      dispatch(switchSuccess(true));
      setSubmitting(false);
    } catch {
      handleAndDispatchError(dispatch);
    }
    //dispatch(setLoadOff());
  };

export { sendNewsletterEmails, sendContactEmail, switchSuccess };
